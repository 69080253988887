import * as React from "react"

import "../styles/main.scss"

const IndexPage = () => (
  <>
    <h1>Datasets</h1>
  </>
)

export default IndexPage
